<template>
  <div class="kpb-map" :style="`aspect-ratio: ${aspectRatio}`"></div>
</template>

<script>
import Mapbox from 'mapbox-gl'
Mapbox.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN

/** Map component */
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({ coordinates: [] }),
    },
    variant: String,
    aspectRatio: {
      type: String,
      default: '16/9',
    },
    mapStyle: {
      type: String,
      default() {
        return process.env.VUE_APP_MAPBOX_STYLE
      },
    },
    showControls: {
      type: Boolean,
      default: true,
    },
    scrollZoom: {
      type: Boolean,
      default: true,
    },
    multipleStyles: {
      type: Array,
      default: null,
    },
  },
  watch: {
    'data.markers': {
      handler: 'initMarkers',
    },
  },
  data() {
    return {
      map: null,
      markers: [],
    }
  },
  methods: {
    initMap() {
      if (!this.data.coordinates) return

      this.map = new Mapbox.Map({
        container: this.$el,
        style: this.mapStyle,
        center: [...this.data.coordinates],
        zoom: 14,
      })

      this.initMarkers()
      this.initControls()
      this.initZoom()
      this.initMultipleStyles()
    },
    initMarkers() {
      this.markers.forEach(m => m.remove())
      let markers = this.data.markers || [this.data.coordinates]

      // const el = document.createElement('div')
      // el.className = 'kpb-map__marker'

      markers.forEach(marker => {
        this.markers.push(
          new Mapbox.Marker({
            // element: el,
            color: 'rgb(214, 88, 6)',
          })
            .setLngLat([...marker])
            .addTo(this.map),
        )
      })
    },
    initControls() {
      if (!this.showControls) return

      const controls = [new Mapbox.FullscreenControl(), new Mapbox.NavigationControl()]
      controls.forEach(c => this.map.addControl(c))
    },
    initZoom() {
      if (!this.scrollZoom) {
        this.map.scrollZoom.disable()
      }
    },

    initMultipleStyles() {
      if (!this.multipleStyles) return

      this.map.addControl(new customController(this.multipleStyles), 'top-left')
      var buttons = document.querySelectorAll('[id=customButton]')

      buttons.forEach((btn, index) => {
        // add bold font
        if (btn.value === this.mapStyle) {
          btn.classList.add('kpb-map__selected')
        }

        btn.addEventListener('click', () => {
          // add map style
          this.map.setStyle(btn.value)

          // add remove bold font
          buttons.forEach(el => el.classList.remove('kpb-map__selected'))
          btn.classList.add('kpb-map__selected')
        })
      })
    },
  },
  mounted() {
    this.initMap()
  },
}

class customController {
  constructor(multipleStyles) {
    this.multipleStyles = multipleStyles
  }

  onAdd(map) {
    this._map = map
    var docFrag = document.createDocumentFragment()
    this.multipleStyles.forEach((style, index) => {
      const button = document.createElement('button')
      button.textContent = style.name
      button.id = 'customButton'
      button.className = `kpb-map__customButton`

      button.value = style.style
      docFrag.appendChild(button)
    })

    this._container = document.createElement('div')
    this._container.appendChild(docFrag)
    this._container.className = 'mapboxgl-ctrl kpb-map__style'

    return this._container
  }
  onRemove() {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}
</script>

<style lang="scss">
.kpb-map {
  background-color: rgba(black, 0.1);
  position: relative;

  .mapboxgl {
    &-ctrl {
      &-attrib,
      &-logo {
        display: none;
      }
      &-group {
      }
    }
  }

  .mapboxgl-ctrl-top-left {
  }

  &__customButton {
    // each style
    font-family: 'Open Sans', sans-serif;

    background: none padding-box rgb(255, 255, 255);
    display: table-cell;

    padding: 0px 17px;
    text-transform: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    user-select: none;
    direction: ltr;
    overflow: hidden;
    text-align: center;
    height: 40px;
    vertical-align: middle;
    color: #565656 !important;
    font-family: Roboto, Arial, sans-serif;
    font-size: 18px;

    // creates the "border"
    box-shadow: rgba(0, 0, 0, 0.3%) 0px 1px 4px -1px;
    min-width: 34px;

    &:hover {
      background: none padding-box rgb(235, 235, 235) !important;
    }
  }
  &__selected {
    color: black !important;
    font-weight: 600 !important;
  }

  &__style {
    display: flex;
    margin: 10px;
  }
}
</style>
